import { type Team } from "@sgdf/client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { meetingService } from "@/core/services/meeting.service";
import { useMeetingId } from "./routes";

const getQueryKey = (meetingId: Ref<string | null>) => computed(() => ["meetings", meetingId, "teams"]);
const getInvalidatePredicate = (meetingId: string) => (query: any) => {
  return (
    query.queryKey[0] === "meetings" &&
    query.queryKey[1] === meetingId &&
    typeof query.queryKey[2] === "string" &&
    ["teams", "participants"].includes(query.queryKey[2])
  );
};

export function useCreateTeamMutation() {
  const meetingId = useMeetingId();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (options: { team: Team }) =>
      meetingService
        .getInstance()
        .getTeamsApi()
        .meetingsTeamsCreate({
          meetingUuid: meetingId.value!,
          notNestedTeam: {
            ...options.team,
          },
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(meetingId),
      });
    },
  });
}

export function useUpdateTeamMutation() {
  const meetingId = useMeetingId();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (options: { team: Team }) =>
      meetingService
        .getInstance()
        .getTeamsApi()
        .meetingsTeamsPartialUpdate({
          meetingUuid: meetingId.value!,
          uuid: options.team.id,
          patchedTeam: {
            ...options.team,
            category_id: options.team.category_id ?? undefined,
          },
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: computed(() => getInvalidatePredicate(meetingId.value!)),
      });
    },
  });
}

export function useDeleteTeamMutation() {
  const meetingId = useMeetingId();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (options: { id: string }) =>
      meetingService.getInstance().getTeamsApi().meetingsTeamsDestroy({
        meetingUuid: meetingId.value!,
        uuid: options.id,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: computed(() => getInvalidatePredicate(meetingId.value!)),
      });
    },
  });
}

export function useTeam() {
  const meetingId = useMeetingId();
  const teamId = useTeamId();
  return useQuery({
    queryKey: computed(() => [...getQueryKey(meetingId).value, teamId]),
    queryFn: () =>
      meetingService.getInstance().getTeamsApi().meetingsTeamsRetrieve({
        meetingUuid: meetingId.value!,
        uuid: teamId,
      }),
  });
}

export const useTeams = () => {
  const meetingId = useMeetingId();
  const role = useRole();

  const enabled = computed(() => !!meetingId && !!role.value?.isFormer);

  return useQuery({
    queryKey: getQueryKey(meetingId),
    queryFn: () =>
      meetingService.getInstance().getTeamsApi().meetingsTeamsList({
        meetingUuid: meetingId.value!,
      }),

    enabled,
    refetchOnMount: false,
    refetchInterval: 5 * 60 * 1000,
  });
};

export function useUpdateTeamParticipantMutation() {
  const meetingId = useMeetingId();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (options: { teamId: string; participants: string[] }) =>
      meetingService
        .getInstance()
        .getTeamsApi()
        .meetingsTeamsParticipantsUpdate({
          meetingUuid: meetingId.value!,
          uuid: options.teamId,
          participantsTeam: {
            participants: options.participants,
          },
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: computed(() => getInvalidatePredicate(meetingId.value!)),
      });
    },
  });
}
